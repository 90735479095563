<template>
  <v-main class="fondo">
    <v-container>
    <v-row align="center" justify="center" class="login">
      <v-col
        cols="12"
        sm="6"
      >
        <v-card
          elevation="24"
          max-width="300"
          class="mx-auto"
          ref="form"
        >
          <v-toolbar
            color="success"
            dark
            elevation="0"
          >INGRESO AL SISTEMA</v-toolbar>
          <v-form @submit.prevent="submit">
            <v-card-text class="rounded-tl-xl ">
              <v-text-field
                v-model="name"
                append-icon="mdi-card-account-details"
                label="Usuario"
                dense
                outlined
                :error-messages="errors.get('name')"
                @keydown="errors.clear('name')"
              />
              <v-text-field
                v-model="password"
                label="Contraseña"
                append-icon="mdi-lock-outline"
                outlined
                dense
                type="password"
                :error-messages="errors.get('password')"
                @keydown="errors.clear('password')"
              />
              <v-autocomplete
                v-model="entity_id"
                :items="entities"
                label="Sucursal"
                item-text="item_data"
                item-value="id"
                outlined
                dense
                :error-messages="errors.get('entity_id')"
                @input="errors.clear('entity_id')"
              ></v-autocomplete>
            </v-card-text>
            <v-card-actions class="mr-2 ml-2 mt-n6 mb-2">
              <v-btn
                type="submit"
                block
                color="success"
                @click="submit"
                large
              >
                Acceder
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  </v-main>
</template>
<script>
import {mapActions, mapState, mapMutations} from "vuex";
import axios from "axios";
import {Errors} from "../../plugins/errors";
export default {
  data: () => ({
    errorMessages: '',
    formHasErrors: false,
    name: '',
    password: '',
    device_name: "vue-app",
    entities: [],
    entity_id: null,
    errors: new Errors(),
  }),

  computed: {
    form () {
      return {
        name: this.name,
        password: this.password,
      }
    },
    ...mapState('auth', ['errores', 'logged'])
  },

  watch: {
    name (search) {
      axios.get(`/api/login/list/entities?search=${search}`)
        .then((response) => {
          response.data.areas.map(function (x){
            return x.item_data = x.area.entity.name + ' --> ' + x.area.name;
          })
          this.entities = response.data.areas;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  mounted() {
    this.error(null)
  },
  methods: {
    ...mapActions({
      login: 'auth/login'
    }),
    ...mapMutations({
      error: 'auth/SET_ERROR'
    }),
    async submit () {
      let user = {
        name: this.name,
        password: this.password,
        entity_id: this.entity_id,
        device_name: "vue-app"
      }

      await this.login(user)

      if (this.logged) {
        await this.$router.push("/inicio").catch(()=>{});
      }else {
        this.errors.record(this.errores)
      }
    }
  },
}
</script>
<style scoped>
  v-container {
    height: 100vh;
  }
  .login {
    margin-top: 15%;
  }
  .fondo {
    background: url(../../assets/svg/background.svg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
  }
</style>
